/** *************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';
/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/docs/ts/latest/guide/browser-support.html
 */

/** *************************************************************************************************
 * BROWSER POLYFILLS
 */

// Add global to window, assigning the value of window itself.
// https://github.com/angular/angular-cli/issues/9827#issuecomment-386154063
(window as any).global = window;

/** IE9, IE10 and IE11 requires all of the following polyfills. **/

import 'core-js/es6/symbol';
import 'core-js/es6/object';
import 'core-js/es6/function';
import 'core-js/es6/parse-int';
import 'core-js/es6/parse-float';
import 'core-js/es6/number';
import 'core-js/es6/math';
import 'core-js/es6/string';
import 'core-js/es6/date';
import 'core-js/es6/array';
import 'core-js/es6/regexp';
import 'core-js/es6/map';
import 'core-js/es6/weak-map';
import 'core-js/es6/set';

import 'core-js/es7/array';
import 'core-js/es7/object';

import 'dom-node-polyfills';  // Run `npm install --save classlist.js`.

/** IE10 and IE11 requires the following for the Reflect API. */
import 'core-js/es6/reflect';


/** Evergreen browsers require these. **/
// Used for reflect-metadata in JIT. If you use AOT (and only Angular decorators), you can remove.
import 'core-js/es7/reflect';

/** *************************************************************************************************
 * APPLICATION IMPORTS
 */

// Waiting on this polyfill to be updated: https://github.com/iamdustan/smoothscroll/issues/113
// When the above fix is ready, we should change this to:
// import smoothscroll from 'smoothscroll-polyfill';
import * as smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

import 'url-polyfill';
// Added only for the "/test-web-components" route
import '@webcomponents/webcomponentsjs/custom-elements-es5-adapter.js';
import '@webcomponents/custom-elements/custom-elements.min.js';


if (!ArrayBuffer['isView']) {
  (ArrayBuffer as any).isView = function(a): boolean {
    return a !== null && typeof(a) === 'object' && a['buffer'] instanceof ArrayBuffer;
  };
}

/** *************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
(window as any).__Zone_enable_cross_context_check = true;
import 'zone.js';  // Included with Angular CLI.
